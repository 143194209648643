<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom card-sticky">
          <div class="card-header">
            <h3 class="card-title font-weight-bolder text-dark">
              Generate files
            </h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="exportType">Type</label>
                      <select
                          @change="onTypeChange($event)"
                          v-model="type"
                          class="form-control form-control-solid"
                          id="exportType"
                      >
                        <option selected value="selectType">
                          Select file type
                        </option>
                        <option value="company_devices_revenue">
                          Devices Revenue
                        </option>
                        <option value="company_loggers">Company Loggers</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="fileFormat">Format</label>
                      <select
                          v-model="format"
                          class="form-control form-control-solid"
                          id="fileFormat"
                      >
                        <option selected value="select">
                          Select file format
                        </option>
                        <option value="xlsx">.xlsx</option>
                        <option
                            v-if="type === 'company_devices_revenue'"
                            value="pdf"
                        >
                          .pdf
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="companyOIB">OIB</label>
                      <select
                          v-model="oib"
                          class="form-control form-control-solid"
                          id="companyOIB"
                      >
                        <option selected value="selectOib">
                          Select company OIB
                        </option>
                        <option
                            v-for="(company, key) in companies"
                            :key="key"
                            :value="company.oib"
                        >
                          {{ company.name }} - OIB: {{ company.oib }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <label>Date Range</label>
                <DatePicker
                    ref="pickerA"
                    class="col-9"
                    v-model="range"
                    mode="dateTime"
                    :attributes="attributes"
                    locale="hr"
                    is-range
                    is24hr
                    :max-date="new Date()"
                    :masks="masks"
                >
                  <template v-slot="{ inputValue, togglePopover }">
                    <input
                        @click="togglePopover()"
                        :value="inputValue.start + ' - ' + inputValue.end"
                        class="form-control"
                        style="height: 42px"
                        readonly
                    />
                  </template>
                  <template v-slot:footer>
                    <div class="row p-2 pr-0">
                      <div class="col pl-5 pr-0">
                        <div
                            @click="setStartEndDate()"
                            class="symbol symbol-40 symbol-light-primary"
                        >
                          <div class="symbol-label cursor-pointer">
                            <span class="svg-icon svg-icon-lx svg-icon-primary">
                              <inline-svg
                                  v-b-tooltip.hover.top="'00:00-23:59'"
                                  src="media/svg/icons/Home/Clock.svg"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row p-2">
                      <div class="col pl-5 pr-0">
                        <div
                            @click="yesterdayA"
                            class="symbol symbol-40 symbol-light-primary"
                        >
                          <div
                              v-b-tooltip.hover.top="'Yesterday'"
                              class="symbol-label cursor-pointer"
                          >
                            Y
                          </div>
                        </div>
                      </div>
                      <div class="col p-0">
                        <div
                            @click="last7DaysA"
                            class="symbol symbol-40 symbol-light-primary"
                        >
                          <div
                              v-b-tooltip.hover.top="'-7 days'"
                              class="symbol-label cursor-pointer"
                          >
                            -7D
                          </div>
                        </div>
                      </div>
                      <div class="col p-0">
                        <div
                            @click="last30DaysA"
                            class="symbol symbol-40 symbol-light-primary"
                        >
                          <div
                              v-b-tooltip.hover.top="'-30 days'"
                              class="symbol-label cursor-pointer"
                          >
                            -30D
                          </div>
                        </div>
                      </div>
                      <div class="col p-0">
                        <div
                            @click="lastMonthA"
                            class="symbol symbol-40 symbol-light-primary"
                        >
                          <div
                              v-b-tooltip.hover.top="'Last month'"
                              class="symbol-label cursor-pointer"
                          >
                            LM
                          </div>
                        </div>
                      </div>
                      <div class="col pl-0">
                        <div
                            @click="thisMonthA"
                            class="symbol symbol-40 symbol-light-primary"
                        >
                          <div
                              v-b-tooltip.hover.top="'This month'"
                              class="symbol-label cursor-pointer"
                          >
                            TM
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </DatePicker>
                <div class="separator separator-dashed my-10"></div>
                <div class="row mt-10 mb-2">
                  <div class="col-md-12">
                    <button
                        @click="download"
                        class="btn btn-light-primary btn-block font-weight-bold text-uppercase"
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import {format} from 'date-fns'
import {mapGetters} from 'vuex'
import addDays from 'date-fns/addDays'
import add from 'date-fns/add'
import startOfMonth from 'date-fns/startOfMonth'
import addMonths from 'date-fns/addMonths'
import endOfMonth from 'date-fns/endOfMonth'
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'

export default {
  name: 'ExportsGenerate',
  components: {
    DatePicker,
  },
  data() {
    return {
      format: 'select',
      type: 'selectType',
      oib: 'selectOib',
      range: {
        start: null,
        end: null,
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: null,
        },
      ],
      fromText: 'Date from',
      toText: 'Date to',
      companies: [],
      isCompaniesLoaded: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserRole', 'currentUserCompany']),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: 'Exports', route: '/exports/generate'},
      {title: 'Generate'},
    ])

    if (
        this.currentUserRole === 'ROLE_ROOT' ||
        this.currentUserRole === 'ROLE_SUPER_ADMIN'
    ) {
      this.getCompanies('companies').then(({data}) => {
        this.companies = [...data['hydra:member']]
        this.isCompaniesLoaded = true
      })
    }

    if (this.currentUserRole === 'ROLE_ADMIN') {
      this.getCompanies(
          'companies',
          `${this.currentUserCompany.replace('/api/v1/companies/', '')}`
      ).then(({data}) => {
        this.companies.push(data)
        this.isCompaniesLoaded = true
      })
    }
  },
  methods: {
    async getCompanies(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    },
    onTypeChange() {
    },
    download() {
      const from = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
      const end = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss")

      if (this.format === 'xlsx') {
        if (this.type === 'company_devices_revenue') {
          window
              .open(
                  `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/exports/xlsx/company-devices-revenue/${this.oib}/${from}/${end}`,
                  '_blank'
              )
              .focus()
        }
      }

      if (this.format === 'pdf') {
        if (this.type === 'company_devices_revenue') {
          let url = `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/exports/pdf/company-devices-revenue/${this.oib}/${from}/${end}`
          console.log("pdf/company-devices-revenue", url)
          window.location.replace(url)

          // window
          //   .open(
          //     `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/exports/pdf/company-devices-revenue/${this.oib}/${from}/${end}`,
          //     '_blank'
          //   )
          //   .focus()
        }
      }
    },
    setStartEndDate() {
      let a = addDays(this.range.start, -1)
      a.setHours(24, 0, 0)
      this.range.start = a

      let b = addDays(this.range.end, 0)
      b.setHours(23, 59, 59)
      this.range.end = b

      this.$refs.pickerA.updateValue({
        start: this.range.start,
        end: this.range.end,
      })
    },

    yesterdayA() {
      this.$refs.pickerA.updateValue({
        start: addDays(new Date(), -1),
        end: addDays(new Date(), -1),
      })
    },
    last7DaysA() {
      this.$refs.pickerA.updateValue({
        start: add(new Date(), {days: -8}),
        end: add(new Date(), {days: -1}),
      })
    },
    last30DaysA() {
      this.$refs.pickerA.updateValue({
        start: add(new Date(), {days: -31}),
        end: add(new Date(), {days: -1}),
      })
    },
    thisMonthA() {
      this.$refs.pickerA.updateValue({
        start: startOfMonth(new Date()),
        end: new Date(),
      })
    },
    lastMonthA() {
      this.$refs.pickerA.updateValue({start: null, end: null})

      this.$refs.pickerA.updateValue({
        start: startOfMonth(addMonths(new Date(), -1)),
        end: endOfMonth(addMonths(new Date(), -1)),
      })

      this.$refs.pickerA.move({
        month: getMonth(addMonths(new Date(), -1)) + 1,
        year: getYear(addMonths(new Date(), -1)),
      })
    },
  },
}
</script>
